import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Maps = ({ currentLang }) => {
  const { t } = useTranslation();
  return (
    <section className="contactus ">
      <div
        className="container"
        style={{ position: "relative" }}
        data-aos="fade-right"
      >
        <h1 style={{ textAlign: "left" }}>{t("Our Location")}</h1>
        <h1
          style={{
            textAlign: "left",
            opacity: "0.15",
            position: "absolute",
            top: "-50%",
            fontSize: "4rem",
            left: "-11px",
          }}
        >
          {t("Our Location")}
        </h1>
      </div>

      <div className="row " data-aos="fade-right">
        <div className="col-md-12 p-5 maps-maps">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.0169835021684!2d46.6826417!3d24.691942899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03087e0b08dd%3A0xb218bc340403d6!2sTIME%20Entertainment!5e0!3m2!1sen!2sae!4v1680511223937!5m2!1sen!2sae"
            width="100%"
            height="600"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Maps;
