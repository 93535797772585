import React from "react";
import logo from "../assets/img/time-e.svg";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../assets/css/style.css";
import ht from "../../src/assets/highlighttime.png";
const Header = ({ currentLang, handleLangChange }) => {
  const { t } = useTranslation();
  const location = useLocation();
  // console.log(location.pathname);
  return (
    <div className="header" >
      <section className="nav_top">
        <nav
          className="navbar navbar-light navbar-expand-md"
          id="mainNav"
          style={{ background: "#260357" }}
        >
          <div className="container" data-aos="fade-down">
            <Link to="/">
              <img
                src={logo}
                style={{ width: 100, height: "100%" }}
                alt="logo"
              />
            </Link>
            <button
              data-bs-toggle="collapse"
              className="navbar-toggler navbar-toggler-right"
              data-bs-target="#navbarResponsive"
              type="button"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              value="Menu"
            >
              <i className="fa fa-bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ms-auto">
                {location.pathname === "/aboutus" ? (
                  <li className="nav-item nav-link navlinkhover currentheaderlink">
                    <Link to="/aboutus" className="header">
                      <span className="artext"> {t("We Are Time")}</span>
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item nav-link navlinkhover">
                    <Link to="/aboutus" className="header">
                      <span className="artext"> {t("We Are Time")}</span>
                    </Link>
                  </li>
                )}
                <li
                  className={`nav-item nav-link navlinkhover ${
                    location.pathname.startsWith("/whatwedo")
                      ? "currentheaderlink"
                      : ""
                  }`}
                >
                  <Link to="/whatwedo/1" className="header">
                    <span className="artext">{t("What We Do")}</span>
                  </Link>
                </li>
                {location.pathname === "/ourwork" || location.pathname.startsWith("/service") ? (
                  <li className="nav-item nav-link navlinkhover currentheaderlink">
                    <Link to="/ourwork" className="header">
                      <span className="artext">{t("Our Work")}</span>
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item nav-link navlinkhover">
                    <Link to="/ourwork" className="header">
                      <span className="artext">{t("Our Work")}</span>
                    </Link>
                  </li>
                )}
                                <li
                  className={`nav-item nav-link navlinkhover ${
                    location.pathname.startsWith("/highlight")
                      ? "currentheaderlink"
                      : ""
                  }`}
                >
  <img
                      alt=""
                      className=""
                      data-aos="fade-left"
                      src={ht}
                      style={{height:"25px"}}
                    />
                </li>
              </ul>
              {/* <div className="row gx-0 ms-4">
                <div className="divoutericon">
                  <a
                    href="https://www.instagram.com/timeentsa/ "
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa fa-instagram faicons"
                  />
                </div>
                <div className="divoutericon">
                  <a
                    href="https://www.linkedin.com/company/time-entertainment/mycompany/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa fa-linkedin faicons"
                  >
                    {" "}
                  </a>
                </div>
                <div className="divoutericon">
                  <a
                    href="https://twitter.com/timeentsa?lang=ar"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa fa-twitter faicons"
                  >
                    {" "}
                  </a>
                </div>
              </div> */} 
              <div className="language ">
                {currentLang === "en" ? (
                  <p
                    onClick={() => handleLangChange("ar")}
                    className="alwaysarabic artext"
                    id="arclicked"
                  >
                    عربي
                  </p>
                ) : (
                  <p
                    onClick={() => handleLangChange("en")}
                    className="artext"
                    id="enclicked"
                  >
                    EN
                  </p>
                )}
                {/* <div class="dropdown">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p onClick={() => handleLangChange("en")}>EN</p>
                  </button>

                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        <p onClick={() => handleLangChange("ar")}>عربي</p>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          {location.pathname === "/about" && <h1>About Page Header</h1>}
          {location.pathname === "/contact" && <h1>Contact Page Header</h1>}
        </nav>
      </section>
    </div>
  );
};

export default Header;
