import React, { useState, useEffect } from "react";
import { baseurl } from "../App";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Whatwedo = ({ currentLang }) => {
  const navigate = useNavigate();
  let { t } = useTranslation();
  
  const [whatwedo_data, setwhatwedo_data] = useState({});
  const [service_data, setservice_data] = useState([]);
  const [loading, setloading] = useState(false);
  const [service_first, setService_first] = useState({});

  let { id } = useParams(); // Get id from URL

  useEffect(() => {
    Whatwedo();
    FetchPost();
  }, [id]); // Re-run when the id changes

  const FetchPost = async () => {
    try {
      let response = await axios.get(`/admin/serviceById/${id}`);
      setwhatwedo_data(response.data.services);
      setloading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeWhatWeDo = (wid) => {
    navigate(`/whatwedo/${wid}`);
  };

  const Whatwedo = async () => {
    try {
      let response = await axios.get("/admin/getactiveservice");
      setservice_data(response.data.services);
      setService_first(response.data.services[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTitleById = (id) => {
    switch (id) {
      case "1":
        return "Ceremonies, Shows, & Celebrations";
      case "2":
        return "Large-scale & Experiential Events";
      case "3":
        return "Event Consultation";
      default:
        return "Service Details";
    }
  };
  const getTextById = (id) => {
    switch (id) {
      case "1":
        return "Over the years, we have perfected the art of conceiving, creating, developing, and producing exceptional ceremonies, shows, and celebrations.Collaborating with some of the most talented creative minds in the industry, we approach each project with the belief that almost anything is possible. We encourage innovative thinking and strive to achieve the technical excellence necessary to bring our visions to life.Our focus remains on the audience, as every ceremony, show, and celebration is designed with them in mind. We honor attendees by crafting kaleidoscopic moments where music, light, performances, and special effects collide to create unforgettable experiences";
      case "2":
        return "Bringing people together is at the heart of what we do.We understand that a strong concept is essential for creating impactful events, including festivals, fan zones, temporary destinations, and pop-up attractions. Our expertise encompasses a wide range of cultural, entertainment, and sports-related gatherings, where we conceptualize, master plan, design, activate and operate engaging environments.This is where our true magic shines brightest: we immerse ourselves in the event's culture, traditions, and rituals to capture its essence. By doing so, we create vivid moments and dynamic spaces that invite the community to come together and celebrate.";
      case "3":
        return "Our event consultation services go beyond the spectacle of events, positioning us as a strategic partner that offers insightful strategies and creative solutions. We expertly navigate the complexities of event planning and execution through a blend of knowledge, innovation, and precision, ensuring success in every project we undertake.Our consultancy services extend our event management capabilities, providing a holistic approach to creating memorable experiences.It includes expert advice and tailored solutions for businesses and organizations aiming to create, manage, and execute events effectively.This encompasses everything from strategy development and conceptualization to financial planning and technical production support. Let us help you strategize the best approach to bring your vision to life.";
      default:
        return "";
    }
  };

  return (
    <div className="whatwedo">
      <section className="banner_chaild">
        <div
          className="container"
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: "100%",
            padding: 0,
            maxWidth: "100%",
          }}
        >
          <div
            className="dd-titlediv container"
            style={{ marginBottom: "40px" }}
          >
            <h1
              className={
                currentLang === "en"
                  ? "title_head_1 pd20 wwe-title"
                  : "title_head_1_ar pd20 wwe-title"
              }
            >
              {t("What we do")}
            </h1>
            <h1 className="tpdb-title wwe-title">{t("What we do")}</h1>
          </div>
        </div>
      </section>

      <section>
  <div className="row">
    {service_data.slice(0, 3).map((item, index) => {
      let title;
      if (index === 0) {
        title = "Ceremonies, Shows, & Celebrations";
      } else if (index === 1) {
        title = "Large-scale & Experiential Events";
      } else if (index === 2) {
        title = "Event Consultation";
      } else {
        title = item.title; // This will not be used since we only show 3 items
      }

      return (
        <div className="item col-md-4 col-6 no-gutters whatwedo-items" key={item.id}>
          <div
            className="whatwe_mains what_wetext whatwedo_divdiv"
            id={item.id}
            onClick={() => changeWhatWeDo(item.id)}
          >
            <img
              alt=""
              src={`${baseurl}${item.image}`}
              style={{ height: "auto", width: "100%" }}
            />
            <p className="whatwe_title wwe-title">
              {title}
            </p>
          </div>
        </div>
      );
    })}
  </div>
</section>



      <section className="text-center" id="content1">
        {!loading ? (
          <div className="background_service">
            <img alt="" src={`${baseurl}${service_first.image}`} width="100%" />
            <div className="container service_child">
              <div className="row whatwedo_mains">
                <div className="col-md-6" style={{ position: "relative" }}>
                  <h1 className="smallwhite-title whatwedo-bigtitle wwe-title">
                    {currentLang === "en"
                      ? service_first.title
                      : service_first.title_ar}
                  </h1>
                </div>
                <div className="col-md-6 scrolling">
                  <p className="wwd-text">
                    {currentLang === "en"
                      ? service_first.content
                      : service_first.content_ar}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="background_service">
            <img
              alt=""
              src={`${baseurl}${whatwedo_data.image}`}
              width="100%"
              className="wwd-img"
            />
            <div className="container service_child">
              <div className="row whatwedo_mains ">
                <div className="col-md-6" style={{ position: "relative" }}>
                  <h1 className="wwe-title wwd-title">
                    {currentLang === "en"
                      ? getTitleById(id) 
                      : whatwedo_data.title_ar}
                  </h1>
                </div>
                <div className="col-md-6 scrolling">
                  <p className="wwd-text">
                    {/* {currentLang === "en"
                      ? whatwedo_data.content
                      : whatwedo_data.content_ar} */}
                       {currentLang === "en"
                      ? getTextById(id) 
                      : whatwedo_data.title_ar}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Whatwedo;
