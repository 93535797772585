import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseurl } from "../App";
// import services from "../assets/img/ourworkbg-1.png"
import { useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Lottie from "react-lottie";
import * as animationData from "../assets/img/98195-loader.json";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SlickSlider from "react-slick";
const Service = ({ currentLang }) => {
  let { t } = useTranslation();

  const [ourwork_main, setourwork_main] = useState({});
  const [service_banner, setService_banner] = useState([]);
  const [loading, setloading] = useState(true);
  // const [service, setService] = useState({});
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style, 
        }}
        onClick={onClick}
      ></div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style, 
        }}
        onClick={onClick}
      />
    );
  }
  useEffect(() => {
    ourworkMains();
  }, []);
  const [isRTL, setIsRTL] = useState(false);
  
  const bannersettings = {
    rtl: currentLang === "ar" ? !isRTL : isRTL,
    infinite: true,
    speed: 600,
    margin: 0,
    slidesToShow: 2,
    slidesToScroll: 1,
    loop: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplayTimeout: 3000,
    smartSpeed: 3000,
    arrows: true, 
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
    
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let { id } = useParams();

  const ourworkMains = async () => {
    console.log(id);
    try {
      let response = await axios.get(`admin/ourworkById/${id}`);
      setourwork_main(response.data.works);
      setService_banner(response.data.works.images);
      setloading(false);
      console.log("work", response.data.works.images);
    } catch (error) {
      console.log(error);
    }
  };
  if (loading) {
    return <Lottie options={defaultOptions} height={400} width={400} />;
  } else {
    return ( 
      <div className="service">
        <section
          className="text-center riyadhseason"
          id="content1"
          style={{
            background: "#000000",
            zIndex: -1,
            backgroundSize: "contain, auto",
          }}
        >
          {/* //ourwork_title */}
          <div className="image serviceslider">
            <SlickSlider
              className="owl-theme banner "
              {...bannersettings}
            >
              {service_banner.map((item) => {
                return (
                  <div className="item srvoitem">
                    <img
                      alt=""
                      src={`${baseurl}${item.image}`}
                      style={{ height: "auto", width: "100%" }}
                      className="banner_images1 wwd-img srvoimg"
                    />
                  </div> 
                );
              })}
            </SlickSlider>


               
            <div className="work_title fadfadx">
              <p className="service-title wwe-title">
                {currentLang === "en"
                  ? ourwork_main.title
                  : ourwork_main.title_ar}
              </p>
              <span>
                {moment(ourwork_main.date_work).format("MMM DD YYYY ")}
              </span>
            </div>
          </div>
          <div className="container content_riyad">
            <div className="row"></div>
            {ourwork_main?.content ? (
              <div className="col-md-12">
                <div
                  className="container"
                  style={{ position: "relative" }}
                  data-aos="fade-down"
                >
                  <div className="dd-titlediv ">
                    <h1 className="abt-title smallwhite-title wwe-title">
                      {t("Project Scope")}
                    </h1>
                    <h1 className="tpdb-title tpdb-title-abt abt-title wwe-title">
                      {t("Project Scope")}
                    </h1>
                  </div>
                </div>
                <p className="service_content service-p">
                  {currentLang === "en"
                    ? ourwork_main.content
                    : ourwork_main.content_ar}
                </p>
              </div>
            ) : null}
          </div>
          {ourwork_main?.content_2 ? (
            <div className="container content_riyad">
              <div className="row"></div>
              <div className="col-md-12">
                <div
                  className="container"
                  style={{ position: "relative" }}
                  data-aos="fade-down"
                >
                  <div className="dd-titlediv ">
                    <h1 className="abt-title smallwhite-title wwe-title">
                      {t("About the Project")}
                    </h1>
                    <h1 className="tpdb-title tpdb-title-abt abt-title wwe-title">
                      {t("About the Project")}
                    </h1>
                  </div>
                </div>
                <p className="service_content service-p">
                  {currentLang === "en"
                    ? ourwork_main.content_2
                    : ourwork_main.content_2_ar}
                </p>
              </div>
            </div>
          ) : null}
        </section>
      </div>
    );
  }
};

export default Service;
