import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseurl } from "../App";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Ourwork = ({ currentLang }) => {
  const navigate = useNavigate();

  const [ourwork_data, setourwork] = useState([]);

  useEffect(() => {
    Ourworkcontrol();
  }, []);

  const Ourworkcontrol = async () => {
    try {
      let response = await axios.get("admin/getactiveourwork");
      setourwork(response.data.works);
      // console.log("gfgsfgsdf", setourwork);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="our_work">
      <section
        className="text-center ourwork "
        id="about"
        style={{ background: "#8003FF", backgroundSize: "cover, auto" }}
      >
        <div
          className="container"
          style={{
            marginBottom: "20px",
            marginRight: 0,
            marginLeft: 0,
            width: "100%",
            padding: 0,
            maxWidth: "100%",
          }}
        >
          {" "}
          <h1 style={{ color: "white", marginBottom: "20px" }}>
            Highlighted by TIME
          </h1>
          <div className="container" style={{ position: "relative" }}></div>
          <div className="row g-0">
            {ourwork_data.reverse().map((item) => {
              return (
                <div
                  className="col-6 ourwork_title "
                  onClick={() => navigate(`/service/${item.id}`)}
                >
                  <img
                    alt=""
                    data-aos="fade-down"
                    data-aos-delay={100}
                    data-aos-easing="ease-in-sine"
                    src={`${baseurl}${item.image_2}`}
                    style={{ height: "auto", width: "100%" }}
                  />
                  <div className="work_title ">
                    <p className="wwtitle wwe-title">
                      {currentLang === "en" ? item.title : item.title_ar}
                    </p>
                    <span>
                      {moment(item.date_work).format("DD MMM YYYY")
                        ? item.date_work
                        : "01 Jan 2019"}
                    </span>
                    {/* <span>{item.date_work}</span> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ourwork;
