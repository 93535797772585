import React, { useState } from "react";
import axios from "axios";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleCareers = (e) => {
    e.preventDefault();

    const formdata = new FormData();

    formdata.append("email", email);
    formdata.append("name", name);
    formdata.append("msg", message);

    axios
      .post(`/admin/send-contact`, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }) 
      .then((res) => {
        console.log("res", res);
        setName("");
        setEmail("");
        setMessage("");
        e.target.reset(); // reset the form to clear all input fields
        document.getElementById("EmailSent").style.display = "block"; // show the message
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section className="contactus for_supplers" style={{ minHeight: "55vh" }}>
      <div
        className="container"
        style={{ position: "relative" }}
        data-aos="fade-right"
      >
        <div className="dd-titlediv">
          <h1 className="title_head_1 pd20 wwe-title smallwhite-title">
            Contact us
          </h1>
          <h1 className="title_head_2 tpdb-title wwe-title smallwhite-title">
            Contact us
          </h1>
        </div>
      </div>
      <div className="row " data-aos="fade-right" style={{ marginTop: "50px" }}>
        <div className="offset-md-2 col-md-6">
          <form onSubmit={handleCareers} encType="multipart/form-data">
            <div className="form">
              <input
                type="text"
                placeholder="Name"
                className="form-control"
                name="name"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                required
              />
              <textarea
                placeholder="message"
                className="form-control"
                rows={5}
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              <button type="submit">
                Send <i className="arrow right" />{" "}
              </button>
              <h4 id="EmailSent" style={{ display: "none",color:"lightgreen" }}>Email sent Successfully.</h4>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contactus;
