import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import { Carousel } from "3d-react-carousal";
import background from "../assets/img/lines.png";
import axios from "axios";
import { baseurl } from "../App";
import CountUp from "react-countup";
import Lottie from "react-lottie";
import SlickSlider from "react-slick";
import * as animationData from "../assets/img/98195-loader.json";
import client1 from "../../src/assets/Client_Logos/1.png";
import client2 from "../../src/assets/Client_Logos/2.png";
import client3 from "../../src/assets/Client_Logos/3.png";
import client4 from "../../src/assets/Client_Logos/4.png";
import client5 from "../../src/assets/Client_Logos/5.png";
import client6 from "../../src/assets/Client_Logos/6.png";
import client7 from "../../src/assets/Client_Logos/7.png";
import client8 from "../../src/assets/Client_Logos/8.png";
import client9 from "../../src/assets/Client_Logos/9.png";
import client10 from "../../src/assets/Client_Logos/10.png";
import client11 from "../../src/assets/Client_Logos/11.png";
import client12 from "../../src/assets/Client_Logos/12.png";
import client13 from "../../src/assets/Client_Logos/13.png";
import client14 from "../../src/assets/Client_Logos/14.png";
import client15 from "../../src/assets/Client_Logos/15.png";
import client16 from "../../src/assets/Client_Logos/16.png";
import client17 from "../../src/assets/Client_Logos/17.png";
import client18 from "../../src/assets/Client_Logos/18.png";
import client19 from "../../src/assets/Client_Logos/19.png";
import client20 from "../../src/assets/Client_Logos/20.png";
import client21 from "../../src/assets/Client_Logos/21.png";
import client22 from "../../src/assets/Client_Logos/22.png";
import client23 from "../../src/assets/Client_Logos/23.png";
import client24 from "../../src/assets/Client_Logos/24.png";
import client25 from "../../src/assets/Client_Logos/25.png";
import client26 from "../../src/assets/Client_Logos/26.png";
import client27 from "../../src/assets/Client_Logos/27.png";
import client28 from "../../src/assets/Client_Logos/28.png";
import client29 from "../../src/assets/Client_Logos/29.png";
import client30 from "../../src/assets/Client_Logos/30.png";
import client31 from "../../src/assets/Client_Logos/31.png";
import client32 from "../../src/assets/Client_Logos/32.png";
import client33 from "../../src/assets/Client_Logos/33.png";
import client34 from "../../src/assets/Client_Logos/34.png";
import client35 from "../../src/assets/Client_Logos/35.png";


import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style, 
      }}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style, 
      }}
      onClick={onClick}
    />
  );
}
const client = [
  { img: client1 },
  { img: client2 },
  { img: client3 },
  { img: client4 },
  { img: client5 },
  { img: client6 },
  { img: client7 },
  { img: client8 },
  { img: client9 },
  { img: client10 },
  { img: client11 },
  { img: client12 },
  { img: client13 },
  { img: client14 },
  { img: client15 },
  { img: client16 },
  { img: client17 },
  { img: client18 },
  { img: client19 },
  { img: client20 },
  { img: client21 },
  { img: client22 },
  { img: client23 },
  { img: client24 },
  { img: client25 },
  { img: client26 },
  { img: client27 },
  { img: client28 },
  { img: client29 },
  { img: client30 },
  { img: client31 },
  { img: client32 },
  { img: client33 },
  { img: client34 },
  { img: client35 },
];

const Homepage = ({ currentLang }) => {
  window.onload = function() {
    setInterval(function() {
      if (document.dir === 'rtl') {
        var but = document.getElementsByClassName('slider-left')[0];
        but.click(); 
        console.log('wah click wah'); 
      }
      else{
        var but = document.getElementsByClassName('slider-right')[0];
        but.click(); 
        console.log('wah click wah'); 
      }
    }, 5000);
  };
  const navigate = useNavigate();
  const [banners, setbanners] = useState([]);
  const [bannersar, setbannersar] = useState([]);
  const [blogs, setblogs] = useState([]);
  const [whatwedo_main, setwhatwedo_main] = useState([]);
  const [ourwork_data, setourwork] = useState([]);
  const [loading, setloading] = useState(true);
  const [abouts, setAbouts] = useState([]);
  const [load, setload] = useState(false);

  const [load_work, setLoad_work] = useState(true);
 
  const { t } = useTranslation();
   

  useEffect(() => {
    banner();
    bannerar();
    Abouts();
    OurworkData();
    whatwedo();
    Blogsdata();
  }, []);
  const [isRTL, setIsRTL] = useState(false);

  const slicksettings = {
    rtl: currentLang === "ar" ? !isRTL : isRTL,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 10,
    slidesToScroll: 1,
    loop: true,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplayTimeout: 2500,
    smartSpeed: 100,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const bannersettings = {
    rtl: currentLang === "ar" ? !isRTL : isRTL,
    infinite: true,
    speed: 1000,
    margin: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    loop: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplayTimeout: 3000,
    smartSpeed: 3000,
    arrows: false,
  };
  const ourworkslider = {
    rtl: currentLang === "ar" ? !isRTL : isRTL,
    infinite: true,
    speed: 1000,  
    slidesToShow: 3,
    slidesToScroll: 1,
    loop: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplayTimeout: 3000,
    smartSpeed: 3000, 
    centerMode: currentLang === "ar" ? isRTL : !isRTL,
    centerPadding: 0,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />

    
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const Abouts = async () => {
    try {
      let response = await axios.get("/admin/getworkhomepage");
      // console.log("about", response.data);
      setAbouts(response.data.about);
    } catch (error) {
      // console.log(error);
    }
  };

  const banner = async () => {
    try {
      let response = await axios.get("/admin/getactivebanner");
      setbanners(response.data.banner);
      setloading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const bannerar = async () => {
    try {
      let response = await axios.get("/admin/getactivebannerar");
      setbannersar(response.data.banner);
      setloading(false);
    } catch (error) {
      // console.log(error);
    }
  };
  
 
  const Blogsdata = async () => {
    try {
      let blog_data = await axios.get("/admin/getactiveblog");
      setblogs(blog_data.data.blog);
    } catch (error) {
      console.log(error);
    }
  };

  const OurworkData = async () => {
    try {
      let response = await axios.get("/admin/getactiveworklimit");
      // console.log("work", response);
      setourwork(response.data.works);
      setLoad_work(false);
    } catch (error) {
      // console.log(error);
    }
  };
  
  let slides = "";

  slides = ourwork_data.map((item) => {
    return (
      <>
        <img
          src={`${baseurl}${item.image_3}`}
          onClick={() => navigate(`/service/${item.id}`)}
          alt=""
        />
        <p>{currentLang === "en" ? item.title : item.title_ar}</p>
      </>
    );
  });
  const whatwedo = async () => {
    try {
      let response = await axios.get("/admin/getactiveservice");
      setwhatwedo_main(response.data.services);
      setload(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Lottie options={defaultOptions} height={400} width={400} />;
  } else {
    return (
      
      <div className="homepage" style={{ height: "100%" }}>
        <section className="banner_main" data-aos="fade-up">
 
          <SlickSlider
              className="owl-theme banner"
              {...bannersettings}
            >
              {
              currentLang === "en" ?
              banners.map((item) => {
              return (
                <div className="banner-owlitem item">
                  <img style={{filter:"brightness(75%)"}}
                    alt=""
                    src={`${baseurl}${item.image}`}
                    className="banner_images bb-imges"
                  />
                  <div className="text-poscc text-banner-sqsq">
                    <h1 className="fw-bolder text-center text-white text-cc wwe-title " >
                      {currentLang === "en" ? item.title : item.title_ar}
                    </h1>
                  </div>
                </div>
              );
            })
            :
            bannersar.map((item) => {
              return (
                <div className="banner-owlitem item">
                  <img style={{filter:"brightness(75%)"}}
                    alt=""
                    src={`${baseurl}${item.image}`}
                    className="banner_images bb-imges"
                  />
                  <div className="text-poscc text-banner-sqsq">
                    <h1 className="fw-bolder text-center text-white text-cc wwe-title ">
                      {currentLang === "en" ? item.title : item.title_ar}
                    </h1>
                  </div>
                </div>
              );
            })
            }
            </SlickSlider>
        </section>
        <section
          data-aos="flip-up"
          data-aos-delay={300}
          className="text-center counter "
          id="yrsprojcli"
        >
          <div className="container">
            <div className="row gx-0">
              <div className="col">
                <header style={{ color: "rgb(255, 255, 255)" }} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row gx-0 counter_homepage">
              <div className="col-md-6">
                <div className="row counter_homepage_child">
                  {/* <div className="col-md-4 homepage_clock"> */}
                  {/* <img src={logo} alt="logo" width="100px" /> */}
                  {/* <p>
                      <Clock />
                    </p>
                  </div> */}
                  <div className="col-md-12">
                    <div className="counter_title">
                      <div className="verticalflip">
                        <p>{t("It's TIME to")}</p>
                        <span className="counter2 wwe-title">{t("Create")}</span>
                        <span className="counter2 wwe-title">{t("Enrich")}</span>
                        <span className="counter2 wwe-title">{t("Celebrate")}</span>
                        <span className="counter2 wwe-title">{t("Entertain")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4 count">
                    <CountUp duration={2} className="counter" end={12} />
                    <span>{t("Years")}</span>
                  </div>
                  <div className="col-4 count">
                    <CountUp duration={2} className="counter" end={341} />
                    <span>{t("Projects")}</span>
                  </div>
                  <div className="col-4 count">
                    <CountUp duration={2} className="counter" end={42} />
                    <span>{t("Clients")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
            className="text-center flipped-background"
            id="about"
            style={{
              backgroundImage: `url(${background})`,
              backgroundSize: "cover, auto",
              backgroundColor: "#260357",
              paddingTop: 0,
            }}
          >

          <div
            dir="ltr"
            className="container time_section homepage_client"
            data-aos="fade-down"
            data-aos-delay={500}
            style={{ position: "relative" }}
          >
            <div className="dd-titlediv">
              <h1
                className={
                  currentLang === "en"
                    ? "title_head_1 pd20"
                    : " title_head_1_ar pd20 wwe-title"
                }
              >
                {t("Our Clients")}
              </h1>
              <h1
                className={
                  currentLang === "en"
                    ? "title_head_2 tpdb-title"
                    : " title_head_2_ar tpdb-title wwe-title"
                }
              >
                {t("Our Clients")}
              </h1>
            </div>

            <SlickSlider
              className="owl-theme client_carts clientowlcarousel"
              {...slicksettings}
            >
              {client.map((item) => {
                return (
                  <div className="item">
                    <img className="ourclienticon" src={item.img} alt="" />
                  </div>
                );
              })}
            </SlickSlider>
          </div>
          <div className="container time_section">
            {abouts.map((item) => {
              return (
                <div className="row pt-5 homepage_about ">
                  <div
                    className="col-md-6 wtime"
                    style={{ position: "relative" }}
                    data-aos="fade-right"
                  >
                  <div className="dd-titlediv">
                    <h1
                      className="title_head_1 pd20 wwe-title smallwhite-title"
                      dangerouslySetInnerHTML={{
                        __html: currentLang === "en" ? item.title_1 : item.title_1_ar,
                      }}
                    />
                    <h1
                      className="title_head_2 tpdb-title wwe-title smallwhite-title"
                      dangerouslySetInnerHTML={{
                        __html: currentLang === "en" ? item.title_2 : item.title_2_ar,
                      }}
                    />
                  </div>

                    <p className="wwd-text" style={{marginBottom:'20px'}}>
                      {/* {currentLang === "en"
                        ? item.content_1
                        : item.content_1_ar} */}With a global perspective and a significant local presence, TIME is a leading Saudi full-service live events and experiences management company in Saudi Arabia, established in 2012. 
                      <br></br>  <br></br>
                      At TIME, we craft experiences that entertain while leaving audiences with lasting memories and stories to share. We are collaborators and innovators dedicated to conveying your message and story in the most efficient, innovative, entertaining, and impactful way possible. Our dynamic, knowledgeable, and responsive team of industry experts provides insightful and highly creative solutions to highlight your TIME'.
                      <br></br><br></br>
                      Being rooted in the Kingdom of Saudi Arabia—a country rich in history and culture—we strive to blend international best practices with local talents and content in an exciting fusion of culture and modernity. We take pride in our inventiveness and often motivate creative thinking in others, resulting in enriching every experience we craft.

                    </p>
                    <div className="row gx-0">
                      <div className="col" style={{ textAlign: "left" }}>
                        <Link to="/aboutus">
                          <button
                            className="btn btn-danger rounded-pill"
                            type="button"
                            style={{
                              textAlign: "left",
                              textTransform: "none",
                              padding: "0.375rem 1rem",
                            }}
                          >
                            {t("About Us")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 nomgpd-img">
                    <img
                      alt=""
                      className="imgvr"
                      data-aos="fade-left"
                      src={`${baseurl}${item.image_1}`}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="container time_section wtime-img"
            style={{
              marginTop: 50,
              marginBottom: 50,
              marginRight: 0,
              marginLeft: 0,
              width: "100%",
              maxWidth: "100%",
            }}
          >
            <div className="container" style={{ position: "relative" }}>
              <div className="dd-titlediv">
                <h1
                  className={
                    currentLang === "en"
                      ? "title_head_1 pd20"
                      : " title_head_1_ar pd20 wwe-title"
                  }
                >
                  {t("What we do")}
                </h1>
                <h1
                  className={
                    currentLang === "en"
                      ? "title_head_2 tpdb-title"
                      : " title_head_2_ar tpdb-title wwe-title"
                  }
                >
                  {t("What we do")}
                </h1>
              </div>
            </div>
            {!load ? (
              <Lottie options={defaultOptions} height={400} width={400} />
            ) : (
              <OwlCarousel
                className="owl-theme client_carts"
                loop
                margin={0}
                items={4}
                dots={false}
                data-aos="fade-down"
                autoplay={false}
                autoplaySpeed={3000}
                autoplayTimeout={4000}
                smartSpeed={1000}
                lazyLoad={1}
                mouseDrag={false}
                touchDrag={false}
                pullDrag={false}
                freeDrag={false}
                responsive={{
                  // Set up responsive options
                  0: { items: 3 },
                  600: { items: 3 }, // Display 1 item for mobile devices
                  1033: { items: 3 }, // Display 2 items for larger screens
                }}
              >
{whatwedo_main.map((item, index) => {
  let title;
  if (index === 0) {
    title = "Ceremonies, Shows, & Celebrations";
  } else if (index === 1) {
    title = "Large-scale & Experiential Events";
  } else if (index === 2) {
    title = "Event Consultation";
  } else {
    title = item.title; // Default title for any other items
  }

  return (
    <div className="item" key={item.id}>
      <div
        className="what_wetext"
        id={item.id}
        onClick={() => navigate(`/whatwedo/${item.id}`)}
      >
        <img
          alt=""
          data-aos="fade-down"
          data-aos-delay={100}
          data-aos-easing="ease-in-sine"
          src={`${baseurl}${item.image_2}`}
          style={{ height: "auto", width: "100%" }}
        />
        <p className="wwe-title">
          {/* {currentLang === "en" ? title : item.title_ar} */}
          {title}
        </p>
      </div>
    </div>
  );
})}

              </OwlCarousel>
            )}
          </div>
          <div
            className="container "
            style={{ marginTop: 50, marginBottom: 50, position: "relative" }}
            data-aos="fade-down"
            data-aos-delay={100}
          >
            <div className="dd-titlediv">
              <h1
                className={
                  currentLang === "en"
                    ? "title_head_1 pd20"
                    : " title_head_1_ar pd20 wwe-title"
                }
              >
                {t("Our Work")}
              </h1>
              <h1
                className={
                  currentLang === "en"
                    ? "title_head_2 tpdb-title"
                    : " title_head_2_ar tpdb-title wwe-title"
                }
              >
                {t("Our Work")}
              </h1>
            </div>
          </div>
          <div className="container carosel_work ourworkcarousel-home">
            <div className="row">
              <div className="col-md-12">
           
              <Carousel
                    slides={slides}
                    autoplay={false}
                    interval={5000}
                    
                  />
              

            {/* <SlickSlider
              className="owl-theme client_carts clientowlcarousel clients_ourwork"
              {...ourworkslider}
            >
              {ourwork_data.map((item) => {
                return (
                  <div className="contents_ourwork">
                     <img className="ourworkhp_img"
                      src={`${baseurl}${item.image_3}`}
                      onClick={() => navigate(`/service/${item.id}`)}
                      alt=""
                    />
                    <p className="ourwork-pp">{currentLang === "en" ? item.title : item.title_ar}</p>
                  </div>
                              );
                            })}
            </SlickSlider> */}
  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default Homepage;
